import Swiper from 'swiper';

(function () {

    const sliders = document.querySelectorAll('.js-card-gallery-slider');
    if (!sliders.length) {
        return;
    }

    for (let i = 0; i < sliders.length; i++) {
        const sliderElement = sliders[i];

        if (sliderElement.querySelectorAll('.swiper-slide').length <= 1) {
            continue;
        }

        let config = {
            spaceBetween: 61,

            breakpoints: {
                320: {
                    slidesPerView: 1.1430,
                    spaceBetween: 32,
                },
                // when window width is >= 768px
                767: {
                    slidesPerView: 1.075,
                },
                // when window width is >= 1025px
                1025: {
                    slidesPerView: 1.5590,
                }
            }
        }

        const slider = new Swiper(sliderElement, config);
    }

}());