import { eventsController } from '../classes/eventsConroller';
import { Marquee } from '../vendor/marquee';
import { isElementVisible } from '../shared/utils';

function initMarquee() {
    const marquees = document.querySelectorAll('.marquee');
    if (!marquees.length) {
        return;
    }

    marquees.forEach(marquee => {
        let checkVisibleEl = isElementVisible(marquee);

        if (!checkVisibleEl) {
            return;
        }

        // new Marquee(marquee);

        const marqueeInstance = new Marquee(marquee);
        const watchScreenForMarquee = () => {
            if (window.innerWidth <= 1024) {
                // console.log('Start marqueeInstance');
                marqueeInstance.start();
            } else {
                // console.log('Stop marqueeInstance');
                marqueeInstance.stop();
            }
        }

        watchScreenForMarquee();

        window.addEventListener('resize', watchScreenForMarquee);
    });
}

function watchScreenWidth() {
    if (window.innerWidth <= 1024) {
        initMarquee();
    } else {

    }
}

eventsController.load(() => {
    watchScreenWidth();
});

window.addEventListener('resize', () => {
    watchScreenWidth();
});
