import { addHtmlScroll, removeHtmlScroll } from '../../js/shared/utils';

// TASK: Аналогичные функции есть в файле js/shared/utils.js


const HTML = document.querySelector('html');
const DISABLE_SCROLL_CLASSES = 'is-disabled-scroll';
const BURGER_MENU_OPENED_CLASSES = 'is-burger-menu-opened';

function disableScroll() {
    /*
     // при блокируем скролл у страницы
    */

    HTML.classList.add(DISABLE_SCROLL_CLASSES);
    removeHtmlScroll();
}

function enableScroll() {
    /*
     // Отмена блокировки СКРОЛЛА страницы,
    */

    HTML.classList.remove(DISABLE_SCROLL_CLASSES);
    addHtmlScroll();
}

function toggleScroll() {
    if (typeof disableScroll === 'function' && typeof enableScroll === 'function') {
        if (HTML.classList.contains(BURGER_MENU_OPENED_CLASSES)) {
            disableScroll();
        } else {
            enableScroll();
        }
    }
}

function eventsBurgerMenu() {
    const burgerBtn = document.querySelector('.js-header-burger');
    const menu = document.querySelector('.js-burger-menu');

    if (!menu) return;

    burgerBtn.addEventListener('click', () => {
        menu.classList.toggle('is-opened');
        HTML.classList.toggle('is-burger-menu-opened');

        if (typeof toggleScroll === 'function') {
            toggleScroll();
        }

    });
}

eventsBurgerMenu();

(function () {
    const btnClose = document.querySelector('.js-burger-menu-close');
    const menu = document.querySelector('.js-burger-menu');

    if (!menu) return;

    btnClose.addEventListener('click', () => {
        menu.classList.toggle('is-opened');
        HTML.classList.toggle('is-burger-menu-opened');

        if (typeof toggleScroll === 'function') {
            toggleScroll();
        }
    });
})();


