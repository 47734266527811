import Swiper from 'swiper';
import { Navigation, EffectFade } from 'swiper/modules';

(function () {

    const containers = document.querySelector('.js-contacts-props');
    if (!containers) {
        return;
    }

    const sliderElement = containers.querySelector('.js-contacts-props-slider');

    if (!sliderElement) {
        console.error('Нет слайдера! Нужно проверить существование .js-contacts-props-slider')
        return;
    }

    if (sliderElement.querySelectorAll('.swiper-slide').length <= 1) {
        return;
    }

    let config = {
        modules: [Navigation, EffectFade],
        slidesPerView: 1,
        spaceBetween: 0,
        watchOverflow: true,
        // speed: 1000,
        autoHeight: true,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },

        direction: 'horizontal',
        breakpoints: {
            0: {
                direction: 'horizontal'
            },
            768: {
                direction: 'vertical',
            },
            1024: {
                direction: 'horizontal'
            }
        }

    }

    // навигация
    const next = containers.querySelector('.js-slider-nav-next');

    if (next) {
        config.navigation = {
            nextEl: next,
            disabledClass: 'is-disabled'
        }
    }

    const slider = new Swiper(sliderElement, config);

    document.addEventListener('click', (e) => {
        const targetElement = e.target.closest('.js-contacts-props-toggle');
        if (targetElement) {
            targetElement.classList.toggle('is-active');

            // slider.slideNext(); // На 768 и менее такой подход не работает
            const currentIndex = slider.activeIndex;
            currentIndex <= 0 ? slider.slideTo(1) : slider.slideTo(0);

        }
    });
}());


