// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Fancybox } from "@fancyapps/ui";

import { eventsController } from './classes/eventsConroller';
import { animationContactsMap, animationProjects, changeTheme, initRellax } from './vendor/animations.js';


// Подключить jQuery для всех модулей и внешних скриптов
// window.$ = window.jQuery = require('jquery');

// Подключение всех js из components и modules
const requireAll = r => r.keys().forEach(r);
requireAll(require.context('../components', true, /\.js$/));
requireAll(require.context('./modules', true, /\.js$/));

// Устанавливаем обработчики на все события из пула
eventsController.setListeners();

// Включаем фенсибокс
Fancybox.bind();

animationProjects();

animationContactsMap();

changeTheme();

initRellax();

// window.addEventListener("resize", function () {
//     ScrollTrigger.refresh();
// });
