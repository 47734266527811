
import Swiper from 'swiper';
import { Navigation, Pagination, Thumbs, EffectFade } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', () => {
    const sliders = document.querySelectorAll('.js-func-gallery');

    sliders.forEach(slider => {
        const parentNav = slider.querySelector('.js-func-gallery-nav');
        const parentHero = slider.querySelector('.js-func-gallery-hero');

        const swiperNav = new Swiper(parentNav, {
            modules: [Navigation, Pagination, Thumbs],
            spaceBetween: 0,

            centeredSlides: false,
            centeredSlidesBounds: true,
            slidesPerView: 'auto',
            watchOverflow: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            direction: 'vertical',
            breakpoints: {
                0: {
                    direction: 'horizontal',
                    spaceBetween: '8rem',
                },
                // when window width is >= 640px
                1025: {
                    direction: 'vertical',
                    spaceBetween: 0,
                }
            },
        });

        const swiperHero = new Swiper(parentHero, {
            // Install modules
            modules: [Navigation, Pagination, Thumbs, EffectFade],
            thumbs: {
                swiper: swiperNav,
                slideThumbActiveClass: 'is-active',
            },
            spaceBetween: 0,
            slidesPerView: 1,
            centeredSlides: false,

            watchOverflow: true,
            speed: 1000,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
        });

    });

});


