document.addEventListener('DOMContentLoaded', (event) => {
    const heroText = document.querySelector('.js-hero-screen-text');

    if (!heroText) {
        return;
    }

    if (!heroText.getAttribute('data-text')) {
        return;
    }


    const dataText = heroText.getAttribute('data-text').split(', ');
    const longestWord = dataText.reduce((a, b) => a.length > b.length ? a : b); // самое длинное слово
    const staticContainer = document.createElement('span');

    staticContainer.className = 'js-hero-screen-text__static';
    staticContainer.textContent = longestWord;

    const replacesSpan = document.createElement('span');
    replacesSpan.className = 'js-hero-screen-text__replaces';

    dataText.forEach((text) => {
        const wordSpan = document.createElement('span');
        wordSpan.className = 'js-hero-screen-text__world';
        wordSpan.textContent = text;

        replacesSpan.appendChild(wordSpan);
    });

    heroText.textContent = '';
    heroText.appendChild(staticContainer);
    heroText.appendChild(replacesSpan);

    // Анимация
    const words = heroText.querySelectorAll('.js-hero-screen-text__world');
    let currentIdx = 0;



    setInterval(() => {
        if (!heroText.classList.contains('is-anim')) {
            heroText.classList.add('is-anim');
        }

        const nextIdx = (currentIdx + 1) % words.length;
        const currentWord = words[currentIdx];
        const nextWord = words[nextIdx];

        currentWord.style.transform = 'translateY(-110%)';
        nextWord.classList.remove('is-hidden');
        nextWord.style.transform = 'translateY(0)';

        setTimeout(() => {
            currentWord.classList.add('is-hidden');
            currentWord.style.transform = 'translateY(110%)';
        }, 400)


        currentIdx = nextIdx;
    }, 2000);
});
