import { closeBurgerMenu } from './../../js/shared/utils';

document.addEventListener('click', (e) => {
    const el = e.target.closest('.js-scroll-to');
    if (!el) {
        return;
    }

    const target = el.getAttribute('data-target');
    if (!target) {
        console.error('Проверьте data-target у элемента, возможно его нет!');
        return;
    }

    const targetEl = document.querySelector(`${target}`);

    if (targetEl) {
        const offsetTop = targetEl.getBoundingClientRect().top + (document.body.scrollTop || window.pageYOffset);

        // У второй секции нет отступа сверху, т.к. есть отступ снизу у первого экрана,
        // поэтому нам нужно сделать компенсацию скролла, т.к. у всех блоков по периметру отступ ~8px (--gap)
        let gap = null ?? 0;
        const computedStyles = getComputedStyle(targetEl);

        // Получаем отступ сверху у элемента
        const paddingTopValue = parseInt(computedStyles.paddingTop, 10) ?? 0;

        // Если отступа нет, то скроллим чуть выше чем положено, опять же на величину рамки по краям
        if (paddingTopValue <= 0) {
            const gapValue = getComputedStyle(document.documentElement).getPropertyValue('--gap');
            gap = parseInt(gapValue.trim(), 10) ?? 0;
        }

        // Установим новую позицию в соответствии с ТЗ
        const setScrollPosition = offsetTop - gap;

        window.scrollTo({ top: setScrollPosition, behavior: 'smooth' });

        if (el.closest('.js-burger-menu')) {
            if (typeof closeBurgerMenu === 'function') {
                closeBurgerMenu();
            }
        }

    }

});