export class eventsController {
    static #pool = {};

    static push(eventKey, userFunction) {
        if (typeof userFunction === 'function') {
            if (eventKey in this.#pool) {
                this.#pool[eventKey].push(userFunction);
            } else {
                this.#pool[eventKey] = [userFunction];
            }
        }
    }

    static execute(targetEventKey) {
        for (const eventKey in this.#pool) {
            if (eventKey === targetEventKey) {
                this.#pool[eventKey].forEach(func => {
                    func();
                });
            }
        }
    }

    static setListeners() {
        if ('ready' in this.#pool) {
            document.addEventListener('DOMContentLoaded', () => {
                this.execute('ready');
            });
        }

        if ('load' in this.#pool) {
            window.addEventListener('load', () => {
                this.execute('load');
            })
        }
    }

    static ready(func) {
        this.push('ready', func);
    }

    static load(func) {
        this.push('load', func);
    }
}