export class Marquee {

    constructor(container, options = {}) {
        this.container = container;
        this.wrapper = this.container.querySelector('.marquee-wrapper');
        this.slide = this.wrapper.querySelector('.marquee-slide');
        this.slideWidth = this.getSlideWith();

        this.isAnimating = true; // для контроля анимации
        this.animationFrameId = null; // ID текущего анимационного кадра

        this.initOptions(options);

        if (this.options.watchResize) {
            this.watchResize();
        }

        this.filling();
        this.animate();
    }

    /**
     * Загрузка настроек
     */
    initOptions(options) {
        let resultOptions = {
            dir: 'ltr',
            speed: 1,
            watchResize: false
        };

        // data-атрибуты
        for (let aid = 0; aid < this.container.attributes.length; aid++) {
            const attr = this.container.attributes[aid];
            const attrName = attr.nodeName.match(/^data-marquee-(.+)$/);
            if (attrName) {
                const fixedAttrName = attrName[1].replace(/-([a-z])/i, '$1');
                resultOptions[fixedAttrName] = attr.nodeValue;
            }
        }

        this.options = resultOptions;
    }

    /**
     * Обработка события изменения ширины экрана
     */
    watchResize() {
        const me = this;

        let resizeTimeout = null;
        let savedViewportWidth = window.innerWidth;

        window.addEventListener('resize', () => {
            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }

            resizeTimeout = setTimeout(() => {
                if (window.innerWidth !== savedViewportWidth) {
                    savedViewportWidth = window.innerWidth;

                    // обновить ширину слайда
                    me.slideWidth = me.getSlideWith();
                }
            }, 300);
        });
    }

    /**
     * Получение ширины слайда
     */
    getSlideWith() {
        return this.slide.getBoundingClientRect().width;
    }

    /**
     * Заполнение marquee-wrapper дубликатами marquee-slide
     */
    filling() {

        // необходимая ширина для заполнения
        const requiredWidth = screen.width > screen.height ? screen.width : screen.height;

        // количество дубликатов, которые нужно сгенерировать
        let takes = this.slideWidth < requiredWidth ? Math.ceil(requiredWidth / this.slideWidth) + 1 : 2;

        // генерируем дубли
        for (let i = 0; i < takes; i++) {
            this.wrapper.append(this.slide.cloneNode(true));
        }
    }

    /**
     * Запуск анимации
     */
    animate() {
        const me = this;

        let translate = 0;

        // ускорение анимации
        const speed = ((+this.options.speed || 1) / 10) * (this.options.dir === 'ltr' ? 1 : -1);

        const move = function () {
            if (!me.isAnimating) {
                // Если анимация остановлена, выходим из функции
                return;
            }

            translate += speed;

            me.wrapper.style.transform = `translate3d(${translate}px, 0, 0)`;
            if (Math.abs(translate) >= me.slideWidth) {
                translate = 0;
            }

            window.requestAnimationFrame(move);
        }

        setTimeout(() => {
            this.container.classList.add('is-animating');
            window.requestAnimationFrame(move);
        }, 100);
    }

    stop() {
        this.isAnimating = false;
        if (this.animationFrameId) {
            window.cancelAnimationFrame(this.animationFrameId); // Останавливаем текущий кадр
        }

        this.container.classList.remove('is-animating');
    }

    start() {
        this.isAnimating = true;
        this.animate();
    }
}