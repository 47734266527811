import { vars } from '../../js/shared/vars';

/*
 * На главной странице при скролле нужно менять цветовую схему шапки
 */

(function () {
    const header = document.querySelector('.js-header-watch');
    if (!header) {
        return
    }

    if (typeof headerChangeColorTheme === 'function') {
        headerChangeColorTheme(header);

        window.addEventListener('scroll', function () {
            headerChangeColorTheme(header);
        });
    }
})();

function headerChangeColorTheme(el) {
    if (pageYOffset >= document.documentElement.clientHeight) {
        el.classList.remove(vars.headerThemeTransparent);
    } else {
        el.classList.add(vars.headerThemeTransparent);
    }
}

// window.onscroll = headerChangeColorTheme;
