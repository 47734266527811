import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

(function () {

    const sliders = document.querySelectorAll('.js-events-slider-container');
    if (!sliders.length) {
        return;
    }

    for (let i = 0; i < sliders.length; i++) {
        const sliderContainer = sliders[i];
        const sliderElement = sliderContainer.querySelector('.js-events-slider');

        if (!sliderElement) {
            console.error('Нет слайдера! Нужно проверить существование .js-events-slider')
            return;
        }

        if (sliderElement.querySelectorAll('.swiper-slide').length <= 1) {
            continue;
        }

        let config = {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 64
        }

        // навигация
        const prev = sliderContainer.querySelector('.js-slider-nav-prev');
        const next = sliderContainer.querySelector('.js-slider-nav-next');

        if (prev && next) {
            config.navigation = {
                prevEl: prev,
                nextEl: next,
                disabledClass: 'is-disabled'
            }
        }


        const slider = new Swiper(sliderElement, config);
    }

}());