import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Rellax from "rellax";

import * as SmoothScroll from "./../vendor/SmoothScroll.min.js";

if (typeof SmoothScroll === 'function') {
    SmoothScroll({
        animationTime: 750, // Время скролла 400 = 0.4 секунды
        stepSize: 75, // Размер шага в пикселях
        accelerationDelta: 30, // Ускорение
        accelerationMax: 2, // Максимальное ускорение
        keyboardSupport: true, // Поддержка клавиатуры
        arrowScroll: 50, // Шаг скролла стрелками на клавиатуре в пикселях

        // Pulse (less tweakable): ratio of "tail" to "acceleration"
        pulseAlgorithm: true,
        pulseScale: 4,
        pulseNormalize: 1,
        touchpadSupport: false, // Поддержка тачпада
    });
}

function objectsScale() {
    // Увеличиваем изображения чтобы, чтобы можно было повесить анимацию параллакса на элемент
    const mm = gsap.matchMedia();
    const objectsScale = document.querySelectorAll('.js-scale');

    if (objectsScale.length) {
        objectsScale.forEach((item) => {
            const getScaleDesktop = item.dataset.scale ?? 1;
            const getScaleMobile = +item.dataset.scale + 0.25 ?? 1;


            mm.add('(min-width: 1025px)', () => {
                gsap
                    .set(item, {
                        scale: getScaleDesktop,
                    })
            })
                .add('(max-width: 1024px)', () => {
                    gsap
                        .set(item, {
                            scale: getScaleMobile,
                        })
                });

        })
    }
}

objectsScale();

export function changeTheme() {
    // Перекрашиваем шапку в зависимости от фона секции

    gsap.registerPlugin(ScrollTrigger);
    const HTML = document.querySelector('html');



    gsap.utils.toArray(".js-theme").forEach(function (elem) {

        const color = elem.getAttribute('data-bg');

        ScrollTrigger.create({
            trigger: elem,
            // start: 'top top',
            // end: 'bottom top',
            start: 'top 40px',
            end: 'bottom 40px',
            onEnter: () => HTML.dataset.bg = color,
            onLeave: () => HTML.dataset.bg = 'dark',
            onLeaveBack: () => HTML.dataset.bg = 'dark',
            onEnterBack: () => HTML.dataset.bg = color,
            // markers: true,
            toggleActions: "play none none reverse"
        });

    });

}


export function animationProjects() {
    console.warn("===> src/js/vendor/animation.JS");
    // Инициализация media queries
    const mm = gsap.matchMedia();
    const breakPoint = 1025;

    const imgHero = document.querySelector('.hero-screen-bg__picture');
    const heroScreenContainer = document.querySelector('.js-hero-screen-anim');
    const headerContainer = document.querySelector('.js-header');

    if (!imgHero || !heroScreenContainer || !headerContainer) {
        return;
    }

    const scrollTop = window.scrollY || window.pageYOffset;
    const heroScreenHeight = heroScreenContainer.offsetHeight ?? 0;

    gsap
        .set(headerContainer, {
            top: '-10%',
        })

    if (scrollTop > heroScreenHeight / 2) {
        console.log('Анимацию не нужно воспроизводить, т.к. мы уже проскроллили первый экран')

        mm.add({
            isDesktop: `(min-width: ${breakPoint}px)`,
            isMobile: `(max-width: ${breakPoint - 1}px)`,
            isTablet: `(min-width: 768px) and (max-width: ${breakPoint - 1}px)`,
            isPhone: `(max-width: 767px)`,
            reduceMotion: "(prefers-reduced-motion: reduce)",
        }, (context) => {
            let { isDesktop, isMobile, isTablet, isPhone, reduceMotion } = context.conditions;

            gsap.set('.hero-screen__logo', {
                scale: isDesktop ? 0.24 : (isPhone ? 0.311 : 0.3372),
                top: isDesktop ? 97 : (isTablet ? 90 : 72),
                alpha: 1,
            })

            gsap.set('.hero-screen__decor--left', {
                bottom: isDesktop ? "60%" : "81.1%",
            })

            gsap.set('.hero-screen__decor--right', {
                top: isDesktop ? "77.5%" : "81.2%",
            })
        });

        gsap.set(headerContainer, {
            top: 0,
            duration: 1.2,
            onComplete: function () {
                headerContainer.classList.remove('is-blendmode');
            },
        })

        gsap.set('.hero-screen__groups', {
            y: 0,
            opacity: 1,
        })

        gsap.set('.hero-screen__logos', {
            y: 0
        })

        gsap.set('.hero-screen-bg__picture', {
            scale: 1,
        })

        return;
    }




    // Проверяем, загружено ли изображение
    if (imgHero.complete) {
        startAnimation();
    } else {
        // Если изображение еще не загружено
        imgHero.addEventListener('load', startAnimation);
    }

    function startAnimation() {
        // Пришлось разделить анимацию на media query, т.к. на десктопе position: bottom/top у
        // .hero - screen__decor--left и.hero - screen__decor--right одна, а на адаптиве другая
        // Возможно и для других элементов что-то поменялось

        mm.add(
            {
                isDesktop: `(min-width: ${breakPoint}px)`,
                isMobile: `(max-width: ${breakPoint - 1}px)`,
                isTablet: `(min-width: 768px) and (max-width: ${breakPoint - 1}px)`,
                isPhone: `(max-width: 767px)`,
                reduceMotion: "(prefers-reduced-motion: reduce)",
            }, (context) => {
                let { isDesktop, isMobile, isTablet, isPhone, reduceMotion } = context.conditions;

                gsap.timeline()
                    .to(headerContainer, {
                        top: 0,
                        duration: 1.5,
                        ease: "power2.inOut",
                        onComplete: function () {
                            headerContainer.classList.remove('is-blendmode');
                        },
                    }, "start")
                    .to('.hero-screen__logo', {
                        alpha: 1,
                        duration: 0
                    }, "start")
                    .to('.hero-screen__logo', {
                        scale: isDesktop ? 0.24 : (isPhone ? 0.311 : 0.3372),
                        top: isDesktop ? 97 : (isTablet ? 90 : 72),
                        duration: 1.5,
                        ease: "power2.inOut",
                    }, "start")
                    .to('.hero-screen__decor--left', {
                        // yPercent: -100,
                        bottom: isDesktop ? "60%" : "81.1%",
                        duration: 3,
                        ease: "power2.out",
                    }, "start")
                    .to('.hero-screen__decor--right', {
                        top: isDesktop ? "77.5%" : "81.2%",
                        duration: 3,
                        ease: "power2.out",
                    }, "start")
                    .to('.hero-screen-bg__overlay', {
                        alpha: 0.3,
                        duration: 3,
                    }, "start")
                    .to('.hero-screen-bg__gradient', {
                        y: 0,
                        duration: 3,
                        height: '100%'
                    }, "start")
                    .fromTo('.hero-screen__groups', {
                        y: '200%',
                        opacity: 0,
                    }, {
                        y: 0,
                        opacity: 1,
                        duration: 1.5,
                        ease: "power2.inOut",
                    }, "start")
                    .fromTo('.hero-screen__logos', {
                        y: '200%',
                    }, {
                        y: 0,
                        duration: 2,
                        ease: "power2.inOut",
                    }, "start")


                    .fromTo('.hero-screen-bg__picture', {
                        scale: 1.1,
                    }, {
                        scale: 1,
                        duration: 4,
                        ease: "power2.out",
                    }, "start")
            });

    }
}

export function animationContactsMap() {
    // Анимация карты
    const mm = gsap.matchMedia();
    const breakPoint = 1025;
    const parent = document.querySelector('.js-contacts');
    const animElement = document.querySelector('.js-contacts-anim');

    if (!parent && !animElement) {
        return;
    }

    gsap.registerPlugin(ScrollTrigger);

    mm.add({
        isDesktop: `(min-width: ${breakPoint}px)`, // Наличие обязательно, даже если не используется. Иначе не работает.
        isPhone: `(max-width: 767px)`,
    }, (context) => {
        let { isDesktop, isPhone } = context.conditions;

        gsap.set(animElement, { scale: isPhone ? 1 : 0 });

        const tween = gsap.to(animElement, {
            scale: 1,
            scrollTrigger: {
                invalidateOnRefresh: true,
                scrub: true,
                trigger: parent,
                start: isPhone ? "top top" : "5% bottom",
                end: () => (isPhone ? 0 : "+=" + parent.offsetHeight),
                onRefresh: self => {
                    gsap.set(animElement, { scale: isPhone ? 1 : 0 });
                }
            }
        });

        let resizeTimeout;
        window.addEventListener('resize', () => {
            // // Фиксим баг, когда мы открыли на десктопе,
            // // проскроллили до блока, анимация скейла выполнилась и мы
            // // резко сжимаем экран до телефона - Scale = 1, и опять
            // // разворачиваем экран на десктоп.

            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                ScrollTrigger.refresh();
            }, 100);
        });
    });

}

export function initRellax() {
    if (document.querySelectorAll('.js-rellax')) {
        new Rellax('.js-rellax', {
            center: true
        });
    }
}



// // Добавляем физику на анимацию
/*
import anime from 'animejs';

const tl = gsap.timeline();

tl.add(
    anime({
        targets: '.your-element',
        opacity: [0, 1],
        duration: 1000
    }),
    "start"
);

tl.add(
    gsap.to('.another-element', {
        x: 200,
        duration: 1,
        ease: "power2.inOut"
    }),
    "+=1"
);
*/