export function isElementVisible(el) {
    // Проверяем отображается элемент на странице
    // или скрыт через display: none или visible: hidden;
    // console.log(isElementVisible(element)); // true | false

    const style = window.getComputedStyle(el);

    return style.display !== 'none'
        && style.visibility !== 'hidden'
        && el.offsetWidth > 0
        && el.offsetHeight > 0;
}


export function setCSSVariable(variables) {
    const root = document.documentElement;

    for (let varName in variables) {
        root.style.setProperty(varName, variables[varName]);
    }
}

export function removeHtmlScroll() {
    console.warn('===> removeHtmlScroll')

    const html = document.documentElement;
    document.body.style.paddingRight = getScrollWidth() + 'px';
    html.classList.add('no-scroll');

    html.setAttribute("style", `--scrollbar-compensate: ${getScrollWidth()}px;`);
};

export function addHtmlScroll() {
    console.warn('===> addHtmlScroll')

    const html = document.documentElement;
    document.body.style.paddingRight = 0;
    html.classList.remove('no-scroll', 'is-disabled-scroll');

    html.removeAttribute("style", `--scrollbar-compensate`);
};

export function closeAllModals() {
    const modals = document.querySelectorAll('.modal.is-opened');
    if (modals.length) {
        modals.forEach(modal => {
            modal.classList.remove('is-opened');
        });

        setTimeout(addHtmlScroll, 400);
    }
}

export function openBurgerMenu() {
    document.documentElement.classList.add('burger-menu-opened', 'no-scroll');
    removeHtmlScroll();
}

export function closeBurgerMenu() {

    const menu = document.querySelector('.js-burger-menu');

    document.documentElement.classList.remove('burger-menu-opened', 'is-burger-menu-opened', 'no-scroll');

    if (menu) {
        menu.classList.remove('is-opened');
    }

    addHtmlScroll();
}

export function toggleBurgerMenu() {
    const html = document.documentElement;
    if (html.classList.contains('burger-menu-opened')) {
        closeBurgerMenu();
    } else {
        openBurgerMenu();
    }
}

export function removeClass(elements, classes) {
    const isMultipleClasses = Array.isArray(classes);
    for (let i = 0; i < elements.length; i++) {
        if (isMultipleClasses) {
            elements[i].classList.remove(...classes);
        } else {
            elements[i].classList.remove(classes);
        }
    }
}

export function openModal(targetID) {
    if (targetID) {
        const target = document.querySelector(`[data-modal-id="${targetID}"]`);
        if (target) {
            removeHtmlScroll();
            closeAllModals();
            target.classList.add('is-opened');

            document.dispatchEvent(
                new CustomEvent('aconti.modalopen', {
                    detail: {
                        modalID: targetID
                    }
                })
            );
        }
    }
}

export function getFileNameFromPath(path) {
    return path.split('\\').pop();
}

export function bytesToMb(bytes) {
    return (bytes / 10e5).toFixed(2);
}

/**
 * Скрытие элемента
 * element - элемент, который нужно анимировать
 * duration - скорость анимации
 * triggerElement - элемент, по которому был произведен клик
 */
export function slideDown(element, duration, triggerElement, cb) {
    const startHeight = element.offsetHeight;
    const endHeight = element.scrollHeight;

    element.style.height = `${startHeight}px`;
    element.style.transition = `height ${duration}ms ease-in-out`;
    triggerElement.classList.add('is-blocked');

    element.style.height = `${endHeight}px`;

    setTimeout(() => {
        // element.style.display = 'block';
        element.style.height = '';
        element.style.transition = '';

        triggerElement.classList.remove('is-blocked');

        if (typeof cb === 'function') {
            cb();
        }
    }, duration);
}

export function slideUp(element, duration, triggerElement, cb) {
    const startHeight = element.offsetHeight;
    const endHeight = 0;

    element.style.height = `${startHeight}px`;
    element.style.transition = `height ${duration}ms ease-in-out`;
    triggerElement.classList.add('is-blocked');

    setTimeout(() => {
        element.style.height = `${endHeight}px`;
    }, 0);

    setTimeout(() => {
        element.style.display = '';
        element.style.height = '';
        element.style.transition = '';

        triggerElement.classList.remove('is-blocked');

        if (typeof cb === 'function') {
            cb();
        }
    }, duration);
}

export function copyToClipboard(text) {
    if (!text) {
        return;
    }

    let formattedText = formattedClipboardText(text);
    const textarea = document.createElement('textarea');
    textarea.style.position = "absolute";
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.visibility = "hidden";


    textarea.value = formattedText;
    document.body.appendChild(textarea);
    textarea.select();

    const result = document.execCommand('copy');
    document.body.removeChild(textarea);

    return result;
}

function formattedClipboardText(text) {
    if (text) {
        const parts = text.trim().replace(/(\n[ ]+)/g, '\n').split(/\n{2,}/);
        return parts.map(part => part.replace(/\n/g, ': ')).join('\r\n');
    }

    return '';
}

/*
function __formattedClipboardText(text) {
    if (text) {
        let formattedText = text.trim().replace(/\s+/g, "\n").split("\n")
            .reduce((acc, line, index, arr) => {
                if (index % 2 === 0) {
                    acc.push(`${line}:`);
                } else {
        // Проверяем, содержит ли текст перенос строк
        const checkTextLineBreak = text.includes('\n');

        if (checkTextLineBreak) {
            // Код плохо работает с таблицей, он не переносит текст по-строчно
            console.log(text);
            let formattedText = text.trim()
                // .replace(/\s+/g, '\n')
                // .replace(/\n\n/g, '\n')
                .replace(/(\n{3,})/g, '\n')
                .split('\n\n')
                .reduce((acc, line, index, arr) => {
                    if (acc.length > 0) {
                        acc[acc.length - 1] += ` ${line}\n`;
                    } else {
                        acc.push(line);
                    }

                    return acc;
                }, []).join('');

            return formattedText;
        }

        return text;
    }
}
*/

function getScrollWidth() {
    const div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);

    const scrollWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    return scrollWidth;
}