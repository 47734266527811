
const accordionsProps = {
    "parentClasse": ".js-accordion",
    "visibleBtnClasses": "is-visible",
    "contentHiddenClasses": "is-hidden-content",
    "duration": "2000"
}

const accordions = document.querySelectorAll('.js-accordion');

const checkLines = (accordion) => {
    const content = accordion.querySelector('.js-accordion-content');
    const wrapper = accordion.querySelector('.js-accordion-content-in');
    const actions = accordion.querySelector('.js-accordion-actions');

    if (!content || !wrapper || !actions) {
        console.warn('Нужно проверить наличие блоков')
        return;
    }

    const setLineHeight = parseInt(window.getComputedStyle(wrapper).lineHeight, 10);
    const setMaxLines = wrapper.dataset.lineClamp ?? 4; // Максимальное кол-во строк текста
    const currentLines = Math.ceil(wrapper.getBoundingClientRect().height / setLineHeight); // кол-во строк текста

    console.warn(`wrapper.getBoundingClientRect().height = ${wrapper.getBoundingClientRect().height}`);

    if (accordion.classList.contains('is-active')) {
        // При ресайзе будет переопределять высоту блока, чтобы потом адекватно работала анимация
        const contentHeight = wrapper.offsetHeight ?? 0;
        content.style.maxHeight = contentHeight + 'px';
    }


    // Если кол-во строк текста больше чем разрешенно - показываем кнопку свернуть/развернуть
    if (currentLines > setMaxLines) {
        actions.classList.add(accordionsProps.visibleBtnClasses);
        content.classList.add(accordionsProps.contentHiddenClasses);

    } else {
        actions.classList.remove(accordionsProps.visibleBtnClasses);
        content.classList.remove(accordionsProps.contentHiddenClasses);
    }
};

const updateAccordionsVisibility = () => {

    if (window.innerWidth <= 767) {
        // Добавил setTimeout, т.к. при резайзе окна высота блока могла остаться прежней оО
        setTimeout(() => {
            accordions.forEach(item => {
                checkLines(item);
            });
        }, 50);
    } else {
        setTimeout(() => {
            accordions.forEach(item => {
                item.classList.remove('is-visible-action');
            });
        }, 50)
    }

};

// document.addEventListener('DOMContentLoaded', (event) => {
// });

window.addEventListener('resize', function () {
    updateAccordionsVisibility();
})

updateAccordionsVisibility();


const elActiveClass = 'is-active';

document.addEventListener('click', function (event) {
    const target = event.target.closest('.js-accordion-toggle');
    if (!target) return;

    const parent = target.closest('.js-accordion');
    if (!parent) return;

    const content = parent.querySelector('.js-accordion-content');
    const contentCollapsed = parent.querySelector('.js-accordion-content-in');

    if (!content || !contentCollapsed) return;

    parent.classList.toggle(elActiveClass);

    const contentCollapsedHeight = contentCollapsed.offsetHeight ?? 0;

    const title = target.querySelector('.js-accordion-toggle-title');
    const textPrimary = target?.dataset?.properties?.text?.primary ?? 'Развернуть';
    const textSecondary = target?.dataset?.properties?.text?.secondary ?? 'Свернуть';

    if (!target.classList.contains(elActiveClass)) {
        // console.log('Разворачиваем блок')

        if (title) {
            title.textContent = textSecondary;
        }

        target.classList.add(elActiveClass);
        content.style.maxHeight = contentCollapsedHeight + 'px';
        // content.classList.remove(accordionsProps.contentHiddenClasses);

    } else {
        // console.log('Сворачиваем блок')

        if (title) {
            title.textContent = textPrimary;
        }

        target.classList.remove(elActiveClass);
        content.style.maxHeight = '';

        // content.classList.add(accordionsProps.contentHiddenClasses);
    }
});
